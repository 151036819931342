import React, { Component, useState } from 'react';
import axios from 'axios';
import {toast, ToastContainer } from "react-toastify";

const Index = (props) => {

    const [email, setEmail] = useState("")
    const [email2, setEmail2] = useState("")
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")

    const submitNewsletter = async e => {
        e.preventDefault();
        const payload = {
            email
        }

        const sendRequest = await axios.post('https://classsuites.site/preskenNewsLetter', payload);
        setEmail("")
        if(sendRequest.status == 200){
            toast.success('Thank your for subscribing to our Newsletter');
        }else{
            toast.error('Invalid Credentials');          
        }
    }


    const contactUs = async e => {
        e.preventDefault();
        const payload = {
            email2, 
            name, 
            message
        }

        const sendRequest = await axios.post('https://classsuites.site/preskenContact', payload);
        setEmail2("")
        setName("")
        setMessage("")
        if(sendRequest.status == 200){
            toast.success('Thank you for contacting us, We will get back to you as soon as possible');
        }else{
            toast.error('Invalid Credentials');            
        }
    }


    return ( 
       <div>
        <ToastContainer />
        <div id="main-wrapper"> 
  
            <header id="header">
                <nav class="primary-menu navbar navbar-expand-md navbar-text-light bg-transparent border-bottom-0">
                    <div class="container position-relative">
                        <div class="col-auto col-lg-2"> 
                            <div class="mt-3" style={{backgroundColor: 'white', borderRadius: '10px', padding: '10px'}}>
                                <a class="logo" href="#" title="Presken"> <img style={{height: '55px'}} class="mt-2" src="/images/logo2.png" alt="Presken"/> </a> 
                            </div>
                        </div>
                        <div class="col navbar-accordion px-0">
                            <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#header-nav"><span></span><span></span><span></span></button>
                            <div id="header-nav" class="collapse navbar-collapse justify-content-end">
                                <ul class="navbar-nav">
                                    <li class="nav-item"><a class="nav-link" data-bs-toggle="modal" data-bs-target="#about" href="#">About Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-auto d-flex justify-content-end">
                            <ul class="social-icons social-icons-light">
                                <li class="social-icons-twitter"><a data-bs-toggle="tooltip" href="https://x.com/Presken_Hotels" target="_blank" title="Twitter"><i class="bx bxl-twitter"></i></a></li>
                                <li class="social-icons-facebook"><a data-bs-toggle="tooltip" href="https://www.facebook.com/preskenhospitality2018/" target="_blank" title="Facebook"><i class="bx bxl-facebook"></i></a></li>
                                <li class="social-icons-instagram"><a data-bs-toggle="tooltip" href="https://www.instagram.com/preskenhospitality/?hl=en" target="_blank" title="Instagram"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

  <div class="hero-wrap">
    <div class="hero-mask opacity-8 bg-dark"></div>
    <div class="hero-bg hero-bg-scroll" style={{backgroundImage: "url('./images/intro-bg.jpg')" }}></div>
    <div class="hero-content py-4 py-lg-0">
      <div class="container">
        <div class="row min-vh-100"> 
          <div class="col-lg-8 align-items-center d-flex flex-column py-5">
              <div class="row py-5 my-auto text-center text-lg-start">
                <div class="col-auto text-white text-center mx-auto mx-lg-0 mb-4 pb-2">
					<div class="countdown" data-countdown-end="2024/09/13 12:00:00"></div>
				</div>
				<div class="col-12 mx-auto">
                  <h1 class="text-9 text-white bg-danger d-inline-block fw-700 rounded px-3 py-2 mb-4">Coming Soon!</h1>
                  <h2 class="text-15 fw-600 text-white mb-4">Our new website is on its way.</h2>
                </div>
                <div class="col-md-8 col-lg-9 col-xl-8 mx-auto me-lg-auto ms-lg-0">
                  <p class="text-5 text-light mb-3">Get notified when we launch.</p>
			  <div class="subscribe-form">
			  <form onSubmit={submitNewsletter}>
                <div class="input-group">
                  <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" id="subscribe-form-email" name="subscribe-form-email" class="form-control required" required placeholder="Enter Your Email Here.." />
                  <button id="subscribe-form-submit" name="subscribe-form-submit" style={{backgroundColor: 'sienna', color: 'white'}} class="btn px-4 shadow-none" type="submit"><span class="d-none d-sm-block">Notify Me</span><span class="text-4 d-block d-sm-none"><i class="fas fa-arrow-right"></i></span></button>
                </div>
              </form>
			  <div class="subscribe-form-result mt-3"></div>

              <div> 
                  <div>
                    <p class="text-white ">Call Us @ </p>
                    <span class="social-icons-instagram"><a class="text-white" data-bs-toggle="tooltip" href="tel:+2348085482332" target="_blank" title="Instagram">Mainland @ +2348085482332</a> </span><br />
                    <span class="social-icons-instagram"><a class="text-white" data-bs-toggle="tooltip" href="tel:+2348035685980" target="_blank" title="Instagram">Island @ +2348035685980</a> </span>
                </div>
              </div>
			  </div>
                </div>
              </div>
          </div>
          <div class="col-lg-4 my-auto py-lg-5">
            <div class="row bg-light rounded text-center mx-0 py-4 mb-4 mb-lg-0">
              <div class="col-11 mx-auto">
                <h3 class="fw-600 mb-3 mt-3">Contact Us</h3>
                <p class="text-black-50 mb-4">For Customer Support, Send us a note.</p>
                <form id="contact-form" onSubmit={contactUs}>
                  <div class="mb-3">
                    <input value={name} onChange={(e)=>setName(e.target.value)} id="name" name="name" type="text" class="form-control border-2" placeholder="Enter Your Name" required />
                  </div>
                  <div class="mb-3">
                    <input value={email2} onChange={(e)=>setEmail2(e.target.value)} id="email" name="email" type="email" class="form-control border-2" placeholder="Enter Your Email" required />
                  </div>
                  <div class="mb-3">
                    <textarea value={message} onChange={(e)=>setMessage(e.target.value)} id="form-message" name="form-message" class="form-control border-2" rows="2" placeholder="Enter Your Message" required ></textarea>
                  </div>
                  <div class="d-grid mt-4">
                    <button id="submit-btn" style={{backgroundColor: 'sienna', color: 'white'}} class="btn" type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    <div id="about" class="modal fade" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title fw-600 text-7 mx-auto">About Us</h5>
            <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-4">
            <div class="row">
            <div class="col-lg-12">
                <div class="featured-box text-center">
                    <p class="text-3">Welcome to Presken Hotels and Resorts, a modern styled hospitality chain with numerous locations (Over 20) in close proximity to the Muritala Muhammed Airport (LOS) and available lodgings placed at the heart of both Ikeja, Lagos Island. Our stay packages and onsite services are modestly tailored to suit both corporate and leisure guests at very best rates available.</p>
                </div>
            </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
        
     );
}
 
export default Index;