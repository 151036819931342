import React, { Component } from 'react'


const PDFViewer = () => {
    return window.location.href = "https://www.preskenhotels.com/menu/foodmenu.pdf"
}

// this will work
 
export default PDFViewer;
