import React, { useEffect } from "react";
import Index from "./Components/index"
import ShowPDF from "./Components/showpdf"
import ScrollToTop from "./scrollToTop.js";
import {Route, Switch} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css"
import "react-toastify/dist/ReactToastify.css"

const App = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return ( 
    <React.Fragment> 
      <ScrollToTop />
      <Switch>
        <Route path="/resturant" component={ShowPDF} />
        <Route path="/" component={Index} />
      </Switch>
    </React.Fragment>
   );
}
 
export default App;
